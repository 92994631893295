import React from 'react';
import '../../styles/pages/tech-info.css';

import Engine from '../../images/engine.jpg';

// Components
import Layout from '../layout';
import Header from '../header';

const tech = {
  en: { acTitle: 'Air conditioning', tcTitle: 'Technique articles' },
  zh: { acTitle: '空調專區', tcTitle: '技術文章' },
};

const TechInfo = (props) => {
  const {
    pageContext: { langKey },
  } = props;

  const viewmorePath = (lang) => {
    switch (lang) {
      case 'en':
        return '/en/tech-info/air-con';
      case 'zh':
        return '/tech-info/air-con';
      default:
        return null;
    }
  };
  return (
    <Layout>
      <div className="TechInfo">
        <Header title={tech[langKey].acTitle} viewmore path={viewmorePath(langKey)} />
        <div className="TechInfo__container">
          <div className="TechInfo__card">
            <div className="TechInfo__card--title">Introduction of R1234YF</div>
            <img src={Engine} width="auto" height="100%" alt="post" />
          </div>
          <div className="TechInfo__card">
            <div className="TechInfo__card--title">Introduction of R1234YF</div>
            <img src={Engine} width="auto" height="100%" alt="post" />
          </div>
        </div>
        <Header title={tech[langKey].tcTitle} viewmore path="/tech-info/tech-section" />
        <div className="TechInfo__container">
          <div className="TechInfo__card">
            <div className="TechInfo__card--title">Introduction of R1234YF</div>
            <img src={Engine} width="auto" height="100%" alt="post" />
          </div>
          <div className="TechInfo__card">
            <div className="TechInfo__card--title">Introduction of R1234YF</div>
            <img src={Engine} width="auto" height="100%" alt="post" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TechInfo;
